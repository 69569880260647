import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, computed, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FwSnackbarService } from '@flywheel-io/vision';

import { OrganizationsService } from '../../../services/organizations/organizations.service';
import { TenantClustersService } from '../tenant-clusters.service';

export interface NewDialogData {
  orgId?: string;
}

@Component({
  selector: 'app-new-tenant-dialog',
  templateUrl: './new-tenant-dialog.component.html',
  styleUrls: ['./new-tenant-dialog.component.scss'],
})
export class NewTenantDialogComponent {

  constructor(
    private orgServices: OrganizationsService,
    private fb: FormBuilder,
    private tenantClustersService: TenantClustersService,
    public snackbarService: FwSnackbarService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: NewDialogData,
  ) {}

  // State
  errorMessage?: string;
  busy: boolean = false;

  // API Interaction
  tenantClusters = this.tenantClustersService.injectTenantClustersQuery().data;
  releaseChannels = this.orgServices.injectOrgReleaseChannelsQuery().data;
  sortedReleaseChannels = computed(() => this.releaseChannels()?.sort((a, b) => a.name.localeCompare(b.name)))

  insertOrgTenantMutation = this.orgServices.injectInsertOrgTenantMutation({
    onSuccess: () => {
      this.snackbarService.show({
        severity: 'success',
        message: 'Tenant created!',
      });
      this.busy=false;
      this.dialogRef.close();
    },
    onError: (err) => {
      this.snackbarService.show({
        severity: 'error',
        message: 'Something went wrong during create.',
      });
      this.errorMessage = err.message+'. Possibly duplicate Tenant Label.';
      this.busy=false;
    },
  });

  // Setup Form
  newTenantForm = this.fb.nonNullable.group({
    org_id: [this.data?.orgId || '', [Validators.required]],
    release_channel: ['', [Validators.required]],
    label: ['', [Validators.required, Validators.max(127)]],
    notes: ['', [Validators.max(1000)]],
    cluster_id: ['', [Validators.required]],
  });

  // Button Actions
  handleAlertClose(): void {
    this.errorMessage = undefined;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  createTenant(): void {
    this.busy=true;

    this.newTenantForm.markAllAsTouched();
    this.newTenantForm.updateValueAndValidity();

    const values = this.newTenantForm.value;

    if (this.newTenantForm.valid) {
      this.insertOrgTenantMutation.mutate({
        org_id: values.org_id!,
        release_channel: values.release_channel!,
        label: values.label!,
        notes: values.notes!,
        cluster_id: values.cluster_id!,
      });
    }
  }
}

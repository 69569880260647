import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateQueryResult, injectQuery } from '@tanstack/angular-query-experimental';
import { lastValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';


export type TenantCluster = {
  name: string,
  description: string,
  cloud_provider: 'azure' | 'aws',
  region: string,
  id: string,
}

@Injectable({
  providedIn: 'root',
})
export class TenantClustersService {

  constructor(
    private http: HttpClient,
  ) {}

  private getTenantClusters(): Observable<TenantCluster[]> {
    return this.http.get<TenantCluster[]>(`${environment.apiBaseUrl}/tenant_clusters`);
  }

  public injectTenantClustersQuery(): CreateQueryResult<TenantCluster[]> {

    return injectQuery(() => ({
      queryKey: ['tenant-clusters'],
      queryFn: () => {
        return lastValueFrom(this.getTenantClusters());
      },
    }));
  }

}

<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
    <h3>New Organization</h3>
  </fw-dialog-header>
  <fw-dialog-content style="overflow-x: auto">
    <div class="body-content" [formGroup]="newOrganizationForm">
      <fw-alert
        *ngIf="errorMessage" severity="error" icon="warning-circle" [title]="'Errors'" [showClose]="true"
        (close)="handleAlertClose()">
        {{ errorMessage }}
      </fw-alert>
      <fw-grid>
        <fw-grid-item [cols]="12" variant="card-background">
          <h4>Organization</h4>
        </fw-grid-item>
        <fw-grid-item [cols]="12" variant="card-background">
          <fw-text-input placeholder="Name" formControlName="name"></fw-text-input>
        </fw-grid-item>
        <fw-grid-item [cols]="12" variant="card-background">
          <fw-text-input placeholder="Descriptive Label" formControlName="label"></fw-text-input>
        </fw-grid-item>
        <fw-grid-item [cols]="12" variant="card-background">
          <h4>Initial User</h4>
        </fw-grid-item>
        <fw-grid-item [cols]="6" variant="card-background">
          <fw-text-input placeholder="First Name" formControlName="given_name"></fw-text-input>
        </fw-grid-item>
        <fw-grid-item [cols]="6" variant="card-background">
          <fw-text-input placeholder="Last Name" formControlName="family_name"></fw-text-input>
        </fw-grid-item>
        <fw-grid-item [cols]="12" variant="card-background">
          <fw-text-input placeholder="Email" formControlName="email"></fw-text-input>
        </fw-grid-item>
      </fw-grid>
    </div>
  </fw-dialog-content>
  <fw-dialog-actions>
    <div class="flex"></div>
    <fw-button variant="outline" (click)="cancel()" [testId]="'btn-add-user-cancel'">Cancel</fw-button>
    <fw-button
      (click)="createOrganization()"
      [testId]="'btn-add-user-send'"
      [disabled]="busy || !newOrganizationForm.valid">
      Create Organization
    </fw-button>
  </fw-dialog-actions>
</fw-dialog>
